<template>
	<CustomTable
		id_table="qualif_fiche"
		ref="qualif_table"
		:items="qualifications"
		:busy.sync="table_busy"
		primaryKey="qualification_id"
		:display_action_button="!horseFicheReadOnly"
		:checkboxes="!horseFicheReadOnly"
	/>
</template>

<script>
import Qualification from '@/mixins/Qualification'
import Navigation from '@/mixins/Navigation'
import { EventBus } from 'EventBus'
import Shutter from '@/mixins/Shutter'
import QualifShutters from '@/mixins/shutters-manager/Qualif'

export default {
	name: 'QualificationFicheListe',
	mixins: [Navigation, Qualification, QualifShutters, Shutter],
	props: {
		/**
		 * horse ou tiers
		 */
		qualificationlink_type: String,
		/**
		 * La FK vers un tiers ou un horse
		 */
		qualificationlink_fk: [String, Number],
	    refresh_table: Number,
		dashboard : Boolean
	},
	data: function() {
		return {
			qualifications: [],
			table_busy: false,
			events_tab: {
				'TableAction::goToAddQualificationToFiche': this.addOrEditQualification,
				'TableAction::goToEditQualificationToFiche': this.addOrEditQualification,
				'TableAction::goToDeleteQualificationToFiche': this.deleteQualification
			},
		}
	},
	created() {
		this.loadQualifications()
	},
	methods: {
		async loadQualifications() {
			this.table_busy = true
			this.qualifications = await this.getFicheQualifications(this.qualificationlink_type, this.qualificationlink_fk)
			this.table_busy = false
		},
		addOrEditQualification(item) {
			const shutter = this.qualifShutters['qualification-fiche-edit']
			shutter.props.qualificationlink_type = this.qualificationlink_type
			shutter.props.qualificationlink_fk = this.qualificationlink_fk

			if (item) {
				shutter.title = this.$t('qualification.modifier_qualification')
				shutter.props.qualificationlink_qualification = item.qualification_id
			} else {
				shutter.title = this.$t('qualification.ajouter_qualification')
				shutter.props.qualificationlink_qualification = null
			}

			shutter.onOk = () => {
			    this.loadQualifications()
			}


			this.shutterPanel().open(shutter)
		},

		deleteQualification(items) {
			let tab_promises = []
			for(let i in items) {
				tab_promises.push(this.deleteQualifToFiche(items[i].qualification_id, this.qualificationlink_fk, this.qualificationlink_type))
			}

			Promise.all(tab_promises)
			.then(() => {
				this.$refs.qualif_table.resetCachedItems()
				this.loadQualifications()
				this.successToast("toast.info_save_succes")
			})
			.catch(() => {
				this.failureToast()
			})
		}
	},
	computed: {
		horseFicheReadOnly() {
			// if(this.dashboard) {
			// 	return true
			// }
			return this.$store.state.horseFicheReadonly
		},
	},
	components: {
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
	}
}
</script>
